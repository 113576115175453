(function() {

  const supplyId = (function() {
    let sup = 0;
    return () => {
      const id = sup;
      sup = sup === Number.MAX_SAFE_INTEGER ? 0 : sup + 1;
      return `veb2023-watch-hover-${id}`;
    };
  }());

  window.veb2023 = window.veb2023 || {};
  window.veb2023.watchHover = (e: HTMLElement, cbObj: DotNetRef): Promise<string> => {
    const watchId = supplyId();
    const watch = {
      onmouseenter: () => {
        e.setAttribute('hovered', '');
        cbObj.invokeMethodAsync<void>('JsMouseEnter');
      },
      onmouseleave: () => {
        e.removeAttribute('hovered');
        cbObj.invokeMethodAsync<void>('JsMouseLeave');
      }
    };
    e.addEventListener('mouseenter', watch.onmouseenter, false);
    e.addEventListener('mouseleave', watch.onmouseleave, false);
    e.__veb2023Watch = e.__veb2023Watch || {};
    e.__veb2023Watch[watchId] = watch;
    return Promise.resolve(watchId);
  };
  window.veb2023.unwatchHover = (e: HTMLElement, watchId: string): Promise<void> => {
    const watch = e.__veb2023Watch && e.__veb2023Watch[watchId];
    if (watch) {
      e.removeEventListener('mouseenter', watch.onmouseenter, false);
      e.removeEventListener('mouseleave', watch.onmouseleave, false);
    }
    return Promise.resolve();
  };
}());
